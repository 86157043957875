import axios from "axios";
import store from '@/store/index.js'
import Cookies from 'js-cookie'

var api = {
    async refresh_token() {
        let data = {
            strategy: "local",
	        action: "refresh",
        	refresh_token: window.localStorage.getItem('refreshToken')
        }
        await axios.post('https://tekie.cloud:3031/authentication', data)
            .then(function (response) {
                window.localStorage.setItem('refreshToken', response.data.refreshToken);
                store.state.token_api = response.data.accessToken;
                Cookies.set('token_api', response.data.accessToken, { secure: true, sameSite: 'Lax' });
            })
            .catch(function (e) {
                console.log("Erro ao tentar atualizar o token: ", e)
            });
    },
    async get_coords(device) {
        var msg_response = '';
        let request_url =
            "https://tekie.cloud:3031/datas?$sort[valueTime]=-1" +
            '&deviceId=' + device +
            "&variableId=1&variableId=2";
        await axios
            .get(request_url, {
                headers: {
                    Authorization: store.state.token_api,
                },
            })
            .then((response) => {
                msg_response = response.data.data
            })
            .catch((e) => {
                console.log("Erro ao tentar pegar os dados: ", e)
            });
        return msg_response;
    },
    async get_lasts_coords(device) {
        var last_coord = '';
        let request_url =
            "https://tekie.cloud:3031/datas?$sort[valueTime]=-1" +
            '&deviceId=' + device + "&variableId=7";
        await axios
            .get(request_url, {
                headers: {
                    Authorization: store.state.token_api,
                },
            })
            .then((response) => {
                try {
                    last_coord = response.data.data
                } catch {
                    last_coord = 'error'
                }
            })
            .catch((e) => {
                console.log("Erro ao tentar pegar os dados: ", e)
            });
        return last_coord;
    },
    async get_variables() {
        var variables = '';
        let request_url = "https://tekie.cloud:3031/variables"
        await axios
            .get(request_url, {
                headers: {
                    Authorization: store.state.token_api,
                },
            })
            .then(function (response) {
                variables = response.data.data
            })
            .catch(function (e) {
                console.log("Erro ao tentar pegar as variáveis: ", e)
            });
        return variables;
    },
    async find_variables(variable_param) {
        var variables = '';
        let request_url = "https://tekie.cloud:3031/variables" + variable_param;
        await axios
            .get(request_url, {
                headers: {
                    Authorization: store.state.token_api,
                },
            })
            .then(function (response) {
                variables = response.data.data
            })
            .catch(function (e) {
                console.log("Erro ao tentar buscar as variáveis: ", e)
            });
        return variables;
    },

    // Requisições dispositivos
    async post_device(data) {
        const headers = {
            "Authorization": store.state.token_api,
        };
        var device = [];

        await axios.post('https://tekie.cloud:3031/devices', data, { headers })

            .then(function (response) {
                device = response;
            })
            .catch(function (e) {
                console.log("Erro ao tentar criar um dispositivo", response);
            });
        return device;
    },
    async get_devices() {
        var devices = [];
        let request_url = "https://tekie.cloud:3031/devices?idGroup=" + store.state.active_group.id;
        await axios
            .get(request_url, {
                headers: {
                    Authorization: store.state.token_api,
                },
            })
            .then(function (response) {
                try{
                    devices = response.data.devices.data
                } catch {
                    devices = []
                }
            })
            .catch(function (e) {
                console.log("Erro ao tentar pegar os dispositivos: ", e);
            });
        return devices;
    },
    async find_devices(device_param) {
        var devices = [];
        let request_url = "https://tekie.cloud:3031/devices?" + device_param
        await axios
            .get(request_url, {
                headers: {
                    Authorization: store.state.token_api,
                },
            })
            .then(function (response) {
                console.log("response: ", response);
                try{
                    devices = response.data.data;
                } catch {
                    devices = []
                }
                if(devices == undefined){
                    devices = response.data.devices.data
                }
            })
            .catch(function (e) {
                console.log("Erro ao tentar procurar os dispositivos: ", e);
            });
        return devices;
    },
    async deleteDevice(request_token, group_request) {
        var msg_response = '';
        let request_url = "https://tekie.cloud:3031/devices?" + request_token + group_request;
        await axios
            .delete(request_url, {
                headers: {
                    Authorization: store.state.token_api,
                },
            })

            .then(function (response) {
                msg_response = 'success';
            })
            .catch(function (e) {
                msg_response = 'failed';
            });
        return msg_response;
    },

    // Requisições Condições
    async get_conditions() {
        var conditions = '';
        let request_url = "https://tekie.cloud:3031/condicoes?idGroup=" + store.state.active_group.id;
        await axios
            .get(request_url, {
                headers: {
                    Authorization: store.state.token_api,
                },
            })

            .then(function (response) {
                if (response.data.error == undefined) {
                    conditions = response.data.condicoes.data
                }
            })
            .catch(function (e) {
                console.log("Erro ao tentar pegar as condições: ", e);
            });
        return conditions;
    },
    async patch_condition(data) {
        const headers = {
            Authorization: store.state.token_api,
          };

        await axios
        .patch("https://tekie.cloud:3031/condicoes", data, { headers })

        .then(function(response) {
        });
    },
    async get_events() {
        var events = '';
        let request_url = "https://tekie.cloud:3031/eventos?idGroup=" + store.state.active_group.id;
        await axios
            .get(request_url, {
                headers: {
                    Authorization: store.state.token_api,
                },
            })

            .then(function (response) {
                console.log("SucessO!")
                if (response.data.error == undefined) {
                    events = response.data.eventos.data;
                }
            })
            .catch(function (e) {
                console.log("Erro ao tentar pegar os eventos: ", e);
            });
        return events;
    },
    async get_conditions_events(conditionId) {
        var events = '';
        let request_url = "https://tekie.cloud:3031/eventos?idGroup=" + store.state.active_group.id;
        await axios
            .get(request_url, {
                headers: {
                    Authorization: store.state.token_api,
                },
            })

            .then(function (response) {
                console.log("SucessO!")
                if (response.data.error == undefined) {
                    events = response.data.eventos.data;
                }
                console.log(response)
            })
            .catch(function (e) {
                console.log("Erro ao tentar pegar os eventos: ", e);
            });
        return events;
    },
    async get_data(device_param, variable_param) {
        var datas = '';
        let request_url = "https://tekie.cloud:3031/datas?$sort[valueTime]=-1" + device_param + variable_param;
        await axios
            .get(request_url, {
                headers: {
                    Authorization: store.state.token_api,
                },
            })
            .then(function (response) {
                try{
                    datas = response.data.data;
                } catch {
                    datas = '';
                }
            })
            .catch(function (e) {
                console.log("Erro ao tentar pegar os dados: ", e);
            });
        return datas;
    },
    async getAllDatas(device_param) {
        var datas = '';
        let request_url = "https://tekie.cloud:3031/datas?$limit=50000" + device_param;
        await axios
            .get(request_url, {
                headers: {
                    Authorization: store.state.token_api,
                },
            })

            .then(function (response) {
                datas = response.data.data;
            })
            .catch(function (e) {
                console.log("Erro ao tentar pegar os dados: ", e);
            });
        return datas;
    },
    async find_data(device_param, variable_param, interval_param) {
        var datas = '';
        let request_url = "https://tekie.cloud:3031/datas?" + device_param + variable_param + interval_param;
        await axios
            .get(request_url, {
                headers: {
                    Authorization: store.state.token_api,
                },
            })
            .then(function (response) {
                console.log("get_data response: ", response)
                datas = response.data;
            })
            .catch(function (e) {
                console.log("Erro ao tentar pegar os dados: ", e);
            });
        return datas;
    },
    async get_events() {
        var events = '';
        let request_url = "https://tekie.cloud:3031/eventos?idGroup=" + store.state.active_group.id
        await axios
            .get(request_url, {
                headers: {
                    Authorization: store.state.token_api,
                },
            })
            .then(function (response) {
                if(response.data.error == undefined){
                    events = response.data.eventos.data
                }
            })
            .catch(function (e) {
                console.log("Erro ao tentar pegar os eventos: ", e)
            });
        return events;
    },
    async get_conditions_device() {
        var conditions_device = [];
        let request_url = "https://tekie.cloud:3031/condicoes-devices?idGroup=" + store.state.active_group.id
        await axios
            .get(request_url, {
                headers: {
                    Authorization: store.state.token_api,
                },
            })
            .then(function (response) {
                conditions_device = response.data.condicoes.data
            })
            .catch(function (e) {
                console.log("Erro ao tentar pegar as condições dos dispositivos: ", e)
            });
        return conditions_device;
    },
    async get_conditions() {
        var conditions = [];
        let request_url = "https://tekie.cloud:3031/condicoes?idGroup=" + store.state.active_group.id
        await axios
            .get(request_url, {
                headers: {
                    Authorization: store.state.token_api,
                },
            })
            .then(function (response) {
                try {
                    conditions = response.data.condicoes.data
                }catch {
                    conditions = []
                }
            })
            .catch(function (e) {
                console.log("Erro ao tentar pegar as condições: ", e)
            });
        return conditions;
    },

    // Requisições grupos
    async get_groups() {
        var groups = [];
        let request_url = "https://tekie.cloud:3031/group"
        await axios
            .get(request_url, {
                headers: {
                    Authorization: store.state.token_api,
                },
            })
            .then(function (response) {
                groups = response.data.data
            })
            .catch(function (e) {
                console.log("Erro ao tentar pegar os grupos: ", e)
            });
        return groups;
    },
    async post_group(data) {
        const headers = {
            "Authorization": store.state.token_api,
        };
        var created_group = [];

        await axios.post('https://tekie.cloud:3031/group', data, { headers })

            .then(function (response) {
                if(response.status == 201 || response.status == 200){
                    created_group = response;
                }
            })
            .catch(function (e) {
                console.log("Erro ao tentar criar um grupo", response);
            });
        return created_group;
    },
    async patch_group(data) {
        const headers = {
            "Authorization": store.state.token_api,
        };
        var msg_response = [];

        await axios.patch('https://tekie.cloud:3031/group', data, { headers })

            .then(function (response) {
                msg_response = response;
            })
            .catch(function (e) {
                console.log("Erro ao tentar criar um grupo", response);
            });
        return msg_response;
    },

    // Requisições dashboard
    async get_dashboards() {
        var dashboard = [];
        let request_url = "https://tekie.cloud:3031/dashboards"
        await axios
            .get(request_url, {
                headers: {
                    Authorization: store.state.token_api,
                },
            })
            .then(function (response) {
                dashboard = response.data.data;
            })
            .catch(function (e) {
                console.log("Erro ao tentar pegar as dashboards: ", e)
            });
        return dashboard;
    },

    async post_dashboard(data) {
        const headers = {
            "Authorization": store.state.token_api,
        };
        var created_dashboard = [];

        await axios.post('https://tekie.cloud:3031/dashboards', data, { headers })

            .then(function (response) {
                created_dashboard = response;
            })
            .catch(function (e) {
                console.log("Erro ao tentar criar uma dashboard: ", response);
            });
        return created_dashboard;
    },
    async patch_dashboard(data) {
        const headers = {
            "Authorization": store.state.token_api,
        };
        var patched_dashboard = [];

        await axios.patch('https://tekie.cloud:3031/dashboards', data, { headers })

            .then(function (response) {
                patched_dashboard = response;
            })
            .catch(function (e) {
                console.log("Erro ao tentar atualizar a dashboard: ", response);
            });
        return patched_dashboard;
    },

    async get_members() {
        var member = [];
        let request_url = "https://tekie.cloud:3031/permissions?idGroup=" + store.state.active_group.id
        await axios
            .get(request_url, {
                headers: {
                    Authorization: store.state.token_api,
                },
            })
            .then(function (response) {
                member = response.data.dadosMembersGroup[0].nome.split('', 1)[0];
            })
            .catch(function (e) {
                console.log("Erro ao tentar pegar os membros: ", e)
            });
        return member;
    },
}

export default api